import React from 'react'
import { Helmet } from 'react-helmet'

const SchemaArticle = ({
  url, title, author, description, datePublished, dateModified, opengraphImage = {},
}) => {
  const structuredDataArticle = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://google.com/article',
    },
    headline: title,
    description,
    image: opengraphImage ? opengraphImage.src : '',
    url: `${process.env.SITE_BASE_URL}${url}`,
    datePublished,
    dateModified,
    author: {
      '@type': 'Person',
      name: author,
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredDataArticle, null, 2)}
      </script>
    </Helmet>
  )
}

export default SchemaArticle
