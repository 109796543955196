import React from 'react'
import styled from '@emotion/styled'
import {
  Flex, Heading, Link, List, ListItem,
} from '../ui'

const StyledList = styled(List)`
  display: inline-block;
`

const TagList = ({ tags = [], ...props }) => {
  const validTags = tags
    .filter(({ name, uri, count }) => (
      !!name && !!uri && count >= process.env.TERM_POST_COUNT_MINIMUM
    ))

  if (!validTags.length) {
    return null
  }

  return (
    <Flex
      {...props}
      flexWrap="wrap"
      alignItems="center"
      flexDirection="row"
    >
      <Heading
        mr={2}
        as="div"
        fontSize={2}
        display="inline-block"
      >
        Címkék:
      </Heading>
      <StyledList>
        {validTags
          .map(({ name, uri }, i) => (
            <ListItem
              py={2}
              pr={2}
              display="inline-block"
              key={`tag-${uri}-${String(i)}`}
            >
              <Link to={uri}>
                {name}
              </Link>
              {validTags.length > 1 && i < validTags.length - 1 ? ',' : null}
            </ListItem>
          ))}
      </StyledList>
    </Flex>
  )
}

export default TagList
